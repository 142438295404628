import { useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import { useAuth } from "../core/context/auth.context";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import TableComponent, {
  TableComponentHandles,
} from "../core/components/table.component";
import Typography from "@mui/material/Typography";
import { useSocket } from "../core/context/socket.context";
import formatCurrency from "../core/helpers/format-currency.helper";
import UserDebtsService, {
  UserDebtsListItem,
} from "../core/service/user-debts.service";
import UserDebtFilterInterface from "@homegame/common/dist/interface/filters/user-debt-filter.interface";
import DebtStatus from "./components/debt-status.component";
import ConfirmationDialogComponent from "../core/components/confirmation-dialog.component";
import DoneIcon from "@mui/icons-material/Done";
import { useToast } from "../core/hooks/use-toast.hook";
import UserDebtStatus from "@homegame/common/dist/enum/user-debt-status.enum";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ListItemIcon, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CardComponent from "../game/components/cards/card.component";
import DialogContent from "@mui/material/DialogContent";
import CardHorizontalComponent from "../game/components/cards/card-horizontal.component";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

const PAGE_SIZE = 5;

const useStyles = makeStyles(() =>
  createStyles({
    wrap: {
      minWidth: "100%",
      paddingLeft: 0,
      height: "100%",
      "& .MuiDataGrid-footerContainer": {
        border: "none",
      },
      "& .MuiDataGrid-cell": {
        border: "none",
        padding: 0,
        minWidth: 200,
      },
      "& .MuiDataGrid-columnHeaders": {
        border: "none",
      },
      "& .MuiDataGrid-root ": {
        border: "none",
      },
    },
    table: {
      "& .MuiDataGrid-row": {
        borderBottom: "1px solid rgba(255,255,255,.6)",
        paddingBottom: 10,
      },
    },
    title: {
      color: "#47528c",
      fontSize: 14,
      fontWeight: 400,
      textShadow: "none",
      // display: "inline-block",
      // width: "100%",
    },
    heading: {
      fontSize: 10,
      width: "100%",
    },
    value: {
      width: "100%",
      fontSize: 12,
      color: "#fff",
      textShadow: "none",
      fontWeight: 300,
      "& svg": {
        fontSize: 14,
        marginBottom: -3,
        color: "#47528c",
      },
    },
    price: {
      color: "#fff",
      textShadow: "none",
      fontSize: 30,
      textAlign: "center",
    },
    btnsContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: 10,
      "& button": {
        textShadow: "none",
        minWidth: 80,
      },
    },
  })
);

interface UserDebtsListProps {
  game?: GameInterface;
  type?: "users" | "to-user";
  userId?: number;
  pageSize?: number;
  height?: number | string;
}

export default function UserDebtsListComponent({
  game,
  type,
  userId,
  pageSize = PAGE_SIZE,
  height,
}: UserDebtsListProps) {
  const classes = useStyles({});
  const userDebtsService = new UserDebtsService();
  const { showToast } = useToast();

  const { user } = useAuth();
  const { transactionSocket } = useSocket();

  const [refreshTrigger, setRefreshTrigger] = useState(1);

  let tableFilters: UserDebtFilterInterface = {
    take: pageSize,
    skip: 0,
    gameId: game?.id,
  };

  if (type === "users") {
    tableFilters.userId = userId;
  } else if (type === "to-user") {
    tableFilters.debtToUserId = userId;
  }

  useEffect(() => {
    if (game) {
      setRefreshTrigger(refreshTrigger + 1);
    }
  }, [game]);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    actionType: "approve" | "decline" | "pay";
    debtItem: UserDebtsListItem | undefined;
  }>({
    open: false,
    actionType: "pay",
    debtItem: undefined,
  });

  const handleDialogOpen = (
    debtListItem: UserDebtsListItem,
    actionType: "approve" | "decline" | "pay"
  ) => {
    setDialogState({
      open: true,
      actionType: actionType,
      debtItem: debtListItem,
    });
  };

  const tableRef = useRef<TableComponentHandles>(null);

  const OptionsMenu = ({ debt }: { debt: UserDebtsListItem }) => {
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [debtInfoOpen, setDebtInfoOpen] = useState(false);

    const handleOptionsMenuClick = (event: Event | React.SyntheticEvent) => {
      setOptionsMenuOpen(true);
      // @ts-ignore
      setAnchorEl(event.currentTarget);
    };

    const handleOptionsMenuClose = () => {
      setOptionsMenuOpen(false);
      setAnchorEl(null);
    };

    const handleButtonClick = (
      debtListItem: UserDebtsListItem,
      actionType: "approve" | "decline" | "pay",
      event: Event | React.SyntheticEvent
    ) => {
      handleOptionsMenuClick(event);
      handleDialogOpen(debtListItem, actionType);
    };

    const handleDebtDialogClickOpen = () => {
      setDebtInfoOpen(true);
      handleOptionsMenuClose();
    };

    const handleDebtDialogClose = () => {
      setDebtInfoOpen(false);
    };

    return (
      <>
        <IconButton
          style={{ filter: "none" }}
          onClick={handleOptionsMenuClick}
          color="primary"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={optionsMenuOpen}
          onClose={handleOptionsMenuClose}
        >
          {user?.id === debt.userId &&
            debt.debts[0].status === UserDebtStatus.NEW && (
              <MenuItem onClick={(e) => handleButtonClick(debt, "pay", e)}>
                <ListItemIcon>
                  <DoneIcon color="success" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Pay debt</ListItemText>
              </MenuItem>
            )}
          {user?.id === debt.debtToUserId &&
            debt.debts[0].status === UserDebtStatus.PENDING && (
              <MenuItem onClick={(e) => handleButtonClick(debt, "approve", e)}>
                <ListItemIcon>
                  <DoneIcon color="success" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Accept debt</ListItemText>
              </MenuItem>
            )}

          {user?.id === debt.debtToUserId &&
            debt.debts[0].status === UserDebtStatus.PENDING && (
              <MenuItem onClick={(e) => handleButtonClick(debt, "decline", e)}>
                <ListItemIcon>
                  <ClearIcon color="error" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Decline debt</ListItemText>
              </MenuItem>
            )}

          <MenuItem onClick={handleDebtDialogClickOpen}>
            <ListItemIcon>
              <InfoOutlinedIcon color="info" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Debt info</ListItemText>
          </MenuItem>
        </Menu>

        <Dialog
          onClose={handleDebtDialogClose}
          open={debtInfoOpen}
          sx={{
            "& .MuiPaper-root": { backgroundColor: "#aab1db" },
            "& .MuiDialogTitle-root": {
              color: "#47528c",
              textShadow: "none",
              textAlign: "center",
              fontWeight: 400,
            },
          }}
        >
          <DialogTitle>
            {type === "users" && `Debt to ${debt.debtToUserName}`}
            {type === "to-user" && `${debt.userName} owes me`}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleDebtDialogClose}
            sx={{
              position: "absolute",
              right: 17,
              top: 13,
              color: (theme) => "#47528c",
              textShadow: "none",
              boxShadow: "none",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ padding: 15 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardComponent
                  icon={<ErrorOutlineIcon />}
                  primary={formatCurrency(debt.totalDebt, debt.currency)}
                  secondary="Total debt"
                />
              </Grid>
              <Grid item xs={6}>
                <CardComponent
                  icon={<CreditScoreIcon />}
                  primary={formatCurrency(debt.payedDebt, debt.currency)}
                  secondary="Payed debt"
                />
              </Grid>
              <Grid item xs={6}>
                <CardComponent
                  icon={
                    <img
                      style={{ width: 30, height: 30, marginBottom: -4 }}
                      src="/ic_stat_onesignal_default.png"
                      alt="chip icon"
                    />
                  }
                  primary={debt.tableName}
                  secondary="Debt in table name"
                />
              </Grid>
              {debt.debts.length > 0 &&
                debt.debts.map((debt) => (
                  <Grid item xs={12}>
                    <CardHorizontalComponent
                      icon={
                        <img
                          style={{ width: 30, height: 30, marginBottom: -4 }}
                          src="/ic_stat_onesignal_default.png"
                          alt="chip icon"
                        />
                      }
                      secondary={
                        <>
                          Game on <br />
                          <b>{dayjs(debt.game?.date).format("D MMMM YYYY")}</b>
                        </>
                      }
                      primary={formatCurrency(debt.debtAmount, debt.currency)}
                    />
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "createdAt",
      headerName: "",
      flex: 1,
      colSpan: 2,
      hideable: false,
      sortable: false,
      headerClassName: "hidden",
      renderCell: (params: GridRenderCellParams) => {
        const debt: UserDebtsListItem = params.row;

        return (
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <Typography className={classes.title}>
                {type === "users" && `Debt to ${debt.debtToUserName}`}
                {type === "to-user" && `${debt.userName} owes me`}
              </Typography>
              <Typography className={classes.value}>
                In {debt.tableName}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              justifyContent="space-between"
              display="flex"
              alignItems="center"
            >
              {debt.debts.length > 0 && (
                <DebtStatus status={debt.debts[0].status} showIcons />
              )}
            </Grid>

            <Grid
              item
              xs={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.price}>
                {formatCurrency(debt.totalDebt, debt.currency)}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <OptionsMenu debt={debt} />
            </Grid>
          </Grid>
        );
      },
    },
  ];

  useEffect(() => {
    const events = ["transactionUpdated", "transactionCreated"];
    if (transactionSocket) {
      events.forEach((eventName) => {
        transactionSocket.on(eventName, (data) => {
          if (
            [data.transaction.fromUserId, data.transaction.toUserId].includes(
              user?.id
            )
          ) {
            // @ts-ignore
            if (tableRef.current) tableRef.current?.triggerRefresh();
          }
        });
      });

      return () => {
        events.forEach((eventName) => {
          transactionSocket.off(eventName);
        });
      };
    }
  }, [transactionSocket]);

  const getRowId = (row: UserDebtsListItem) =>
    row.userId?.toString() +
    row.debtToUserId?.toString() +
    row.tableId?.toString();

  const onConfirmationClose = () =>
    setDialogState({
      open: false,
      actionType: "pay",
      debtItem: undefined,
    });

  const onPaymentConfirmed = async () => {
    try {
      if (dialogState.actionType === "decline") {
        await userDebtsService.declineTableDebt(dialogState.debtItem!);
        showToast("Debt declined!", "success");
      } else if (dialogState.actionType === "approve") {
        await userDebtsService.approveTableDebt(dialogState.debtItem!);
        showToast("Debt approved!", "success");
      } else if (dialogState.actionType === "pay") {
        await userDebtsService.payTableDebt(dialogState.debtItem!);
        showToast("Debt payed!", "success");
      }
    } catch (e) {
      showToast((e as Error).message, "error");
    } finally {
      setRefreshTrigger(refreshTrigger + 1);
      onConfirmationClose();
    }
  };

  return (
    <Grid item xs={12} className={classes.wrap}>
      <TableComponent
        columns={columns}
        service={userDebtsService}
        filters={tableFilters}
        height={height}
        className={classes.table}
        refresh={refreshTrigger}
        getRowId={getRowId}
      />

      <ConfirmationDialogComponent
        text={`Are you sure you want to ${dialogState.actionType} debt in table ${dialogState.debtItem?.tableName}?`}
        open={dialogState.open}
        onClose={onConfirmationClose}
        actionText={`${dialogState.actionType}`}
        action={onPaymentConfirmed}
      />
    </Grid>
  );
}
