import BaseService from "./base.service";
import UserDebtInterface from "@homegame/common/dist/interface/user-debt.interface";
import UserDebtFilterInterface from "@homegame/common/dist/interface/filters/user-debt-filter.interface";

export interface UserDebtsListItem {
  debts: UserDebtInterface[];
  currency: string;
  debtToUserId: number;
  debtToUserName: string;
  payedDebt: number;
  tableId: number;
  tableName: string;
  totalDebt: number;
  userId: number;
  userName: string;
}

export default class UserDebtsService extends BaseService {
  baseEndpoint = "user-debts";

  async list(
    params?: UserDebtFilterInterface
  ): Promise<[UserDebtsListItem[], number]> {
    return this.baseList(params);
  }

  async payTableDebt(params?: {
    userId: number;
    tableId: number;
    debtToUserId: number;
    totalDebt: number;
  }): Promise<[UserDebtsListItem[], number]> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/pay-table-debt`,
      data: params,
    });
  }

  async approveTableDebt(params?: {
    userId: number;
    tableId: number;
    debtToUserId: number;
    totalDebt: number;
  }): Promise<[UserDebtsListItem[], number]> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/approve-table-debt`,
      data: params,
    });
  }

  async declineTableDebt(params?: {
    userId: number;
    tableId: number;
    debtToUserId: number;
    totalDebt: number;
  }): Promise<[UserDebtsListItem[], number]> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/decline-table-debt`,
      data: params,
    });
  }
}
