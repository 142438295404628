import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import GameStatusText from "./game-status-text";
import IconButton from "@mui/material/IconButton";
import AddToGameDialog from "./components/add-to-game.component";
import SendIcon from "@mui/icons-material/Send";
import GamePageTabsComponent from "./tabs/game-page-tabs.component";
import { useGame } from "./game.context";
import LinearProgress from "@mui/material/LinearProgress";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      height: "calc(100svh - 170px)",
      flexDirection: "row",
      alignItems: "flex-start",
      maxWidth: 500,
      margin: "0 auto",
      justifyContent: "center",
    },
    headingButtons: {
      margin: "0 auto",
      zIndex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 500,
      width: "100%",
    },
    heading: {
      display: "none",
      fontWeight: 500,
      fontSize: 19,
      paddingLeft: 40,
      paddingRight: 40,
      textAlign: "center",
      position: "relative",
      borderBottom: "2px solid rgba(245,0,87,.6)",
      paddingBottom: 7,
      marginTop: 10,
      maxWidth: 500,
      width: "100%",
      margin: "0 auto",
      "@media (orientation: landscape)": {
        marginTop: -90,
      },
    },
  })
);

export default function GameView() {
  const classes = useStyles({});
  const navigate = useNavigate();
  const {
    game,
    reloadGame,
    isNew,
    isOngoing,
    isCanceled,
    isCashier,
    gameId,
    isFetching,
  } = useGame();

  const [invitePlayerOpened, setInvitePlayerOpened] = useState<boolean>(false);

  const toggleInvitePlayer = async () => {
    setInvitePlayerOpened(!invitePlayerOpened);
  };

  const onInviteAddClose = async () => {
    setInvitePlayerOpened(false);
    await reloadGame();
    navigate(`/game/${game?.id}/players`);
  };

  return (
    <>
      <div className={classes.headingButtons}>
        <IconButton
          style={{ padding: 9 }}
          component={Link}
          to={`/tables/${game?.tableId}/view`}
          aria-label="back"
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
        <div>
          {isCashier && (isNew || isOngoing) && (
            <IconButton onClick={toggleInvitePlayer} size="large">
              <SendIcon />
            </IconButton>
          )}
        </div>
      </div>
      <Grid className={classes.wrapper} container id="pageHeading">
        <Typography className={classes.heading} variant="h5" gutterBottom>
          {game?.table?.name}
        </Typography>

        {isFetching && <LinearProgress style={{ width: "100%" }} />}

        {!isCanceled && <GameStatusText />}

        <GamePageTabsComponent />

        {(isNew || isOngoing) && (
          <AddToGameDialog
            open={invitePlayerOpened}
            type="invite"
            gameId={Number(gameId)}
            onClose={onInviteAddClose}
          />
        )}
      </Grid>
    </>
  );
}
