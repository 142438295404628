import UserInterface from "@homegame/common/src/interface/user.interface";
import UserFilterInterface from "@homegame/common/src/interface/filters/user-filter.interface";
import BaseService from "./base.service";
import { axios } from "../api";
import { API_URL } from "../config";

export default class UserService extends BaseService {
  baseEndpoint = "users";

  async list(params?: UserFilterInterface): Promise<[UserInterface[], number]> {
    return this.baseList(params);
  }

  async single(id: string | number | undefined) {
    return super.baseSingle<UserInterface>(id);
  }

  async save<UserInterface>(
    userData: Partial<UserInterface>,
    id: number | string | undefined | null
  ): Promise<UserInterface> {
    if (id) {
      try {
        return axios.put(`${API_URL}${this.baseEndpoint}/${id}`, userData);
      } catch (e) {
        console.error(e);
        throw BaseService.handleError(e);
      }
    }
    return this.baseCreate(userData);
  }
}
