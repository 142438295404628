import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useQuery } from "@tanstack/react-query";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "../../core/components/loading-button.component";
import SelectUserComponent from "../../core/components/select-user.component";
import GameService from "../../core/service/game.service";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import { useToast } from "../../core/hooks/use-toast.hook";

interface AddToGameDialogProps {
  onClose?: () => any;
  gameId: number;
  open: boolean;
  type?: "add" | "invite";
}

interface SendInviteComponentProps {
  gameId: number;
  onSend?: () => void;
  type?: "add" | "invite";
  noOptionsText?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#fff",
      fontWeight: 300,
      textAlign: "center",
    },
    form: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "10px 27px 15px 27px",
      width: "100%",
      "& .MuiAutocomplete-noOptions": {
        color: "#fff",
      },
    },
    btn: { width: 200, marginTop: 15 },
  })
);

function SendInviteComponent({
  onSend,
  gameId,
  type = "add",
  noOptionsText = "You have no players registered at your table",
}: SendInviteComponentProps) {
  const classes = useStyles({});
  const { showToast } = useToast();
  const gamesService = new GameService();
  const [isCodeSending, setIsCodeSending] = useState<boolean>(false);

  const defaultValues = { player: undefined };
  const { handleSubmit, control } = useForm<{ player: UserInterface }>({
    defaultValues,
  });

  const { isFetching: isLoading, data: game } = useQuery({
    queryKey: [`singleGameData`, { gameId }],
    queryFn: () => gamesService.single(gameId),
    initialData: undefined,
  });

  const onSubmit = async ({ player }: { player: UserInterface }) => {
    setIsCodeSending(true);
    try {
      if (type === "invite") {
        await gamesService.invitePlayer(gameId, player.id!);
        showToast("Player invited!", "success");
      } else {
        await gamesService.addPlayer(gameId, player.id!);
        showToast("Player added!", "success");
      }
      if (onSend) onSend();
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsCodeSending(false);
    }
  };

  if (isLoading) return <>Loading...</>;

  let usersList: UserInterface[] = [];

  if (game?.table?.players) {
    const gamePlayersIds =
      game.players?.map((gamePlayer) => gamePlayer.playerId) || [];

    usersList = game?.table?.players
      ?.filter((tablePlayer) => !gamePlayersIds.includes(tablePlayer.playerId))
      .filter((tablePlayer) => !!tablePlayer.playerId)
      .map((tablePlayer) => tablePlayer.player!);
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      className={classes.form}
    >
      <Controller
        render={({ field }) => (
          <SelectUserComponent
            noOptionsText={noOptionsText}
            inputPlaceholder="Type user name or phone and select from dropdown"
            label="Select player"
            {...field}
            list={usersList}
          />
        )}
        name="player"
        control={control}
        rules={{ required: "Field required" }}
      />

      <LoadingButton
        isLoading={isCodeSending}
        text={`${type === "add" ? "Add" : "Invite"} player to game`}
        className={classes.btn}
        color="secondary"
      />
    </form>
  );
}

export default function AddToGameDialog({
  onClose,
  gameId,
  open,
  type = "add",
}: AddToGameDialogProps) {
  const classes: any = useStyles({});

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog
      maxWidth={"lg" as "lg"}
      PaperProps={{ style: { padding: 1, alignItems: "center" } }}
      fullWidth
      onClose={handleClose}
      aria-labelledby="invite-dialog-title"
      open={open}
    >
      <DialogTitle id="invite-dialog-title" className={classes.title}>
        {type === "add" ? "Add" : "Invite"} player
      </DialogTitle>

      <SendInviteComponent type={type} gameId={gameId} onSend={handleClose} />
    </Dialog>
  );
}
