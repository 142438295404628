import Grid from "@mui/material/Grid";
import { useAuth } from "../../core/context/auth.context";
import useScreenSize from "../../core/hooks/use-screen-size";
import useUserOs from "../../core/hooks/use-user-os";
import UserDebtsListComponent from "../user-debts-list.component";

export default function MyDebtsTab() {
  const { user } = useAuth();
  const screenSize = useScreenSize();
  const { isIOS } = useUserOs();

  const innerContainerSize = screenSize.height - (isIOS ? 180 : 165); // magic numbers

  return (
    <Grid
      item
      xs={12}
      height="80vh"
      style={{
        backgroundColor: "#aab1db",
        boxShadow: "1px 1px 3px rgb(0 0 0 / 40%)",
        padding: 15,
        borderRadius: 15,
      }}
    >
      <UserDebtsListComponent
        height={innerContainerSize}
        type="users"
        userId={user?.id}
        pageSize={Math.floor((innerContainerSize - 100) / 80)}
      />
    </Grid>
  );
}
